<!--
 * @Description: 登录页
-->
<template>

	<div class="login" :style="[getStyle()]">
		<!-- logo区域 -->
		<img :src="project.setting.login.logo" class="login-logo" v-if="project.setting.login.logo != undefined"
			:style="[logoStyle()]" />

		<p class="loginTitle" :style="[titleStyle()]">{{ project.setting.login.title }}</p>

		<p class="loginTitle2" :style="[titleStyle2()]" v-if="project.setting.login.title2">
			{{ project.setting.login.title2 }}
		</p>

		<!-- 账号密码登录区域 -->
		<ul class="flex-column col-center" :style="[inputViewStyle()]">

			<li class="login-field" :style="[inputUserPwdStyle()]">
				<!-- <i class="icon icon-shouji"></i> -->
				<input type="text" class="telPwdCss" :style="[inputStyle()]" placeholder="手机号码" v-model="account" />
			</li>

			<div v-if="project.setting.login.inputShowLine" class="line"></div>

			<li class="login-field" :style="[inputUserPwdStyle()]">
				<!-- <i class="icon icon-suo"></i> -->
				<input type="password" class="telPwdCss" :style="[inputStyle()]" :placeholder="pwdPlaceholder"
					v-model="password" />
				<span class="text_sms" :style="[btnSmsStyle()]" @click="getCode" :v-bind:disabled='btnDisabled'
					v-if="project.setting.login.showTelLogin">
					{{ btnTitle }}</span>
			</li>

			<div v-if="project.setting.login.inputShowLine" class="line"></div>

		</ul>


		<!-- 登录按钮区域 -->
		<input type="button" class="btn" value="登录" :style="[btnStyle()]"
			:value="project.setting.login.btnTitle || ''" />


		<!-- 用户协议 -->
		<div class="justify-evenly group_agreement">

			<img src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62848c7e1ec7be00116d6a08/16528540526882119274.png"
				class="image_agreement" />
			<text class="text_agreement">我已阅读并同意</text>
			<text class="text_agreement_2" :style="[agreementStyle()]">用户服务协议</text>
			<text class="text_agreement">和</text>
			<text class="text_agreement_2" :style="[agreementStyle()]">隐私政策</text>
		</div>


	</div>

</template>

<script>
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "LoginBar",

		data() {
			return {
				checked: false, //是否勾选了协议  小程序审核官方要求默认是不同意
				pwdPlaceholder: '请输入密码',
				userName: "微信会员", //微信的昵称
				userHeadImg: "https://mall.5izg.cn/upload/ico/default_avatar_male.png", //微信的头像
				account: "",
				password: "",
				sitenum: "",
				openid: '',
				appid: "",
				code: "",
				autoRegister: '0',
				userCode: '',
				btnDisabled: true, //获取验证码按钮是否可以点击
				btnTitle: '获取验证码' //获取验证码按钮显示文字
			};
		},

		computed: {
			...mapGetters(["project"]),
		},

		methods: {
			//协议的字体颜色
			agreementStyle() {
				return {
					color: this.project.setting.login.backgroundColor,
				}
			},
			//背景图
			getStyle() {
				//页面的背景图
				return {
					'background-image': 'url(' + this.project.setting.login.bgImg + ')',
					'background-color': this.project.setting.login.backgroundColor,
					'min-height': '812px',
					// 'height': '100vh'
				}
			},

			logoStyle() {
				return {
					width: this.project.setting.login.logoWidth,
					height: this.project.setting.login.logoHeight,
					'border-radius': this.project.setting.login.logoRadius
				}
			},
			// 登录页文字样式1
			titleStyle() {
				return {
					marginTop: this.project.setting.login.titleMarginTop,
					color: this.project.setting.login.titleFontColor,
				}
			},
			// 登录页文字样式2
			titleStyle2() {
				return {
					marginTop: this.project.setting.login.titleMarginTop2,
					color: this.project.setting.login.titleFontColor2,
				}
			},

			// 账号密码登录区域样式
			inputViewStyle() {
				return {
					width: '100%',
					// height: this.project.setting.login.btnHeight,
					// width: this.project.setting.login.btnWidth,
					// color: this.project.setting.login.btnTitleColor,
					// fontSize: this.$unit(this.project.setting.login.btnTitleSize),
					// fontWeight: 400,
					// background: this.project.setting.login.btnBgColor,
					// borderRadius: `${this.project.setting.login.btnUpperRadius}px ${this.project.setting.login.btnUpperRadius}px ${this.project.setting.login.btnLowerRadius}px ${this.project.setting.login.btnLowerRadius}px`,
					marginTop: this.project.setting.login.inputMarginTop,
					marginBottom: this.project.setting.login.inputMarginBottom,


				}
			},
			// 账号密码输入框样式
			inputUserPwdStyle() {
				return {
					// height: this.project.setting.login.btnHeight,
					display: 'flex',
					width: '90%',
					background: this.project.setting.login.inputBackground, // 'rgba(236, 247, 246, 1)',
					'border-radius': this.project.setting.login.inputRadius, //'10px',
					border: this.project.setting.login.inputBorder, //'1px solid #1A1A1A'
					// border: '1px solid ' + this.project.setting.login.btnBgColor,
					// 'border-bottom': '1px solid ' + this.project.setting.login.btnBgColor
				}
			},
			// 账号密码input样式  比如文字颜色 在深颜色背景下要用白字
			inputStyle() {
				return {
					color: this.project.setting.login.inputColor,
					'text-align': this.project.setting.login.inputTextAlign
				}
			},

			// 按钮样式
			btnStyle() {
				return {
					height: this.project.setting.login.btnHeight,
					width: this.project.setting.login.btnWidth,
					color: this.project.setting.login.btnTitleColor,
					fontSize: this.project.setting.login.btnTitleSize,
					fontWeight: 400,
					background: this.project.setting.login.btnBgColor,
					borderRadius: `${this.project.setting.login.btnUpperRadius}px ${this.project.setting.login.btnUpperRadius}px ${this.project.setting.login.btnLowerRadius}px ${this.project.setting.login.btnLowerRadius}px`,
					marginTop: `${this.project.setting.login.btnMarginTop}px `,
					marginBottom: `${this.project.setting.login.btnMarginBottom}px `,
				}
			},
		},

	};
</script>

<style lang="scss" scoped>
	.loginTitle {
		width: 100%;
		height: 30px;
		font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
		font-weight: 700;
		font-size: 24px;
		color: #000000;
		line-height: 0px;
		text-align: center;
		font-style: normal;
		text-transform: none;
	}

	.loginTitle2 {
		width: 100%;
		height: 30px;
		font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
		font-weight: 400;
		font-size: 16px;
		color: #808080;
		line-height: 0px;
		text-align: center;
		font-style: normal;
		text-transform: none;
	}


	.btn {
		background: rgb(188, 175, 166);
		border-radius: 50px;
		text-align: center;
		color: white;
		font-size: 16px;
		margin-top: 10px;
		width: 90%;
	}



	.login {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		color: #d2d2d2;

		background-repeat: no-repeat; //取消重复平铺
		background-position: top; //将背景图片定位到顶部	
		background-size: 100%; //是不是应该加一个图片高度的设置,这样可以控制

		.login-logo {
			// height: 60px;
			// width: 60px;
			margin-top: 80px;
			margin-bottom: 70px;
			border-radius: 50%;
		}

		//用户名和密码输入框
		.login-field {
			height: 48px;
			border-radius: 23px;
			margin-bottom: 10px;
			padding-left: 24px;

			// opacity: 0.1;
			// display: flex;
			// align-items: center;

			input {
				height: 100%;
				outline: none;
				border: 0;
				font-size: 16px;
				color: #383838;
				background: transparent;
			}

			input::-webkit-input-placeholder,
			textarea::-webkit-input-placeholder {
				color: #dbd9dc;
				font-size: 14px;
				font-weight: 700;
			}

			i {
				font-size: 20px;
				margin-right: 5px;
			}
		}


		.login-line {
			position: relative;
			margin-top: 100px;
			font-size: 12px;

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: 110px;
				transform: translateY(-50%);
				width: 60px;
				height: 1px;
				background: #d2d2d2;
			}

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 110px;
				transform: translateY(-50%);
				width: 60px;
				height: 1px;
				background: #d2d2d2;
			}
		}



		.image_agreement {
			margin-top: 3rpx;
			width: 30rpx;
			height: 30rpx;
		}

		.group_agreement {
			margin-top: 146rpx;
			padding-left: 87rpx;
			padding-right: 86rpx;
		}

		.text_agreement {
			margin-bottom: 3rpx;
			// color: #ffffff99;
			font-size: 24rpx;
			font-family: '.AppleSystemUIFont';
			line-height: 30rpx;
			letter-spacing: -2rpx;
		}

		.text_agreement_2 {
			margin-bottom: 3rpx;
			// color: #ffffff;
			font-size: 24rpx;
			font-family: '.AppleSystemUIFont';
			line-height: 30rpx;
			letter-spacing: -2rpx;
		}

	}


	.telPwdCss {
		width: 95%;
	}

	.line {
		width: 100%;
		margin-top: -10px;
		    margin-bottom: 20px;
		height: 1px;
		background: #CCCCCC;
		border-radius: 30rpx;
	}
</style>